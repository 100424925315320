var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "v-layout",
        {
          staticClass: "sleepTrack-card",
          attrs: { row: "", wrap: "", "pt-3": "", "gap-xs-3": "" }
        },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("p", { staticClass: "starling-explanatory" }, [
              _vm._v(_vm._s(_vm.$t("app.tools.sleep_tracker.subtitle")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                {
                  staticClass:
                    "create-sleep-track-card d-flex align-center justify-center",
                  attrs: { flat: "" }
                },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        "justify-center": "",
                        "align-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "primary-button",
                            {
                              staticClass: "px-3",
                              attrs: { depressed: "" },
                              on: { click: _vm.onCreate }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "app.tools.sleep_tracker.actions.new_entry"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.hasSleepTrack
            ? [
                _c("v-flex", { attrs: { xs12: "" } }, [
                  _c(
                    "h3",
                    { staticClass: "starling-h3 StarlingPrimary2--text" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("app.tools.sleep_tracker.sections.previous")
                        )
                      )
                    ]
                  )
                ]),
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c("sleep-track-list", {
                      attrs: { values: _vm.storeSleepTrack },
                      on: { update: _vm.refreshSleepTrack }
                    })
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }